import logo from '../static/img/besafer_logo.png';

export const IS_DEV = process.env.NODE_ENV === 'development';
export const DEV_BASE_DOMAIN =
  'https://besafer.privatecloud.hispatecanalytics.com';

export const PROD_BASE_DOMAIN =
  'https://besafer.privatecloud.hispatecanalytics.com';
export const DEFAULT_LANGUAGE = 'es';
export const APP_LOGO = logo;
export const APP_NAME = 'Besafer 2.0';
export const SECONDARY_COLOR = '#85bb23';
export const SEPARATOR = '$[]$';
export const ATCS_ES = {
  agronomy: 'Agronomía',
  environment: 'Medio Ambiente',
  food: 'Alimentaria',
  mining: 'Minería',
  healthSecurity: 'Salud y Seguridad',
};
export const ATCS_TRANSLATED = {
  Agronomy: 'Agronomy',
  Food: 'Food',
  Environment: 'Environment',
  Mining: 'Mining',
  'Health and Security': 'Health and Security',
};
export const REQUEST_TIME = '600000';
export const UE_COUNTRIES = ['ES', 'IT', 'FR', 'PT'];
export const ALL_LANGUAGES = [
  { key: 'ENG', value: 'en' },
  { key: 'SPA', value: 'es' },
  { key: 'FRA', value: 'fr' },
  { key: 'POR', value: 'pt' },
  { key: 'ITA', value: 'it' },
];
export const AFTER_DATE_LIMIT = '2019-01-01';
export const NOTIFY_TIME_LONG = 7000;
export const baseUrlPre = 'besafer.privatecloud.hispatecanalytics.com'; // Endpoint pre para websocket
export const ALLOWED_SAMPLES_PARAMETERS = 250;
export const RELEASE_VERSION = '2.2.4';