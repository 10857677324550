<template>
  <f7-page>
    <div v-if="loaded">
      <AgronomySampleSummary />
      <br />
      <AgronomySampleDetail v-if="showDetails" />

      <NotificationSpinner
        v-if="!showDetails"
        :message="$t('pendingPaymentSampleResult')"
        :alert="true"
      />

      <NotificationSpinner
        v-if="inProcess"
        :message="$t('inProcessSampleResult')"
        :warning="true"
      />

      <GoBack
        class="margin-top"
        :url-name="urlName"
        :go-to-url-name="true"
      />
    </div>
  </f7-page>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import AgronomySampleSummary from '@/components/Agronomy/AgronomySampleSummary/index.vue';
import GoBack from '@/components/UtilityComponents/GoBack/index.vue';
import NotificationSpinner from '@/components/NotificationSpinner';
import { NOTIFY_TIME_LONG } from '@/js/constants';
import AgronomySampleDetail from '../../../components/Agronomy/AgronomySampleDetail/AgronomySampleDetail.vue';

export default {
  components: {
    AgronomySampleDetail,
    AgronomySampleSummary,
    NotificationSpinner,
    GoBack,
  },
  data() {
    return {
      loaded: false,
      currentSampleSummary: {},
    };
  },
  computed: {
    urlName() {
      if (this.lastRouteGeotoolboxSamples) {
        return 'GisActionsPage';
      }

      return this.lastRouteHome ? 'Home' : 'AgronomySamplesPage';
    },
    showDetails() {
      return this.currentSampleSummary?.status_code !== '5';
    },
    inProcess() {
      return this.currentSampleSummary?.status_code === '2';
    },
    // showNotificationSpinner() {
    //   return this.currentSampleSummary?.status_code === '5';
    // },
    ...mapState('filter', ['currentSampleId']),
    ...mapState('samples', [
      'lastRouteHome',
      'lastRouteGeotoolboxSamples',
    ]),
  },
  async mounted() {
    try {
      this.$f7.preloader.show();
      await this.getSampleDetails(this.currentSampleId);
    } catch (error) {
      const msg = JSON.parse(error.message);
      if (msg.data && msg.data.length === 0) {
        this.setSampleDetails(msg.data);
        return;
      }

      this.$notifyDX(
        {
          message: error,
          width: 350,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }

    this.currentSampleSummary =
      await this.getCurrentSampleBasedOnFilter();

    // if (!this.showDetails) {
    //   this.$notifyDX(
    //     {
    //       message: this.$t('pendingPaymentSampleResult'),
    //       width: 550,
    //     },
    //     'warning',
    //     7000
    //   );
    // }
  },
  methods: {
    ...mapActions('agronomySamples', [
      'getSampleDetails',
      'setSampleDetails',
    ]),
    ...mapActions('samples', ['getCurrentSampleBasedOnFilter']),
  },
};
</script>
