import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
// Modules:
import user from './modules/userModule';
import company from './modules/companyModule';
import samples from './modules/samples/samples';
import filter from './modules/filters/filter';
import navbar from './modules/navbar';
import documentation from './modules/documentation';
import clientMenu from './modules/clientMenu';
import contact from './modules/contact';
import leftPanel from './modules/leftPanel';
import riskAnalysis from './modules/riskAnalysis';
import foodStats from './modules/samples/foodStats';
import environmentStats from './modules/samples/environmentStats';
import gis from './modules/gis';
import gisEditor from './modules/gisEditor';
import registerFarm from './modules/registerFarm';
import advancedServices from './modules/advancedServices';
import weather from './modules/weather';
import nutritionalFollowing from './modules/nutritionalFollowing';
import gisComparator from './modules/gisComparator';
import nutritionalMonitoring from './modules/nutritionalMonitoring';
import phitomonitoringPopup from './modules/phitomonitoringPopup';
import breadcrumb from './modules/breadcrumb';

// filters
import homeFilter from './modules/filters/home';
import agronomyFilter from './modules/filters/agronomy';
import environmentFilter from './modules/filters/environment';
import environmentDetailsFilter from './modules/filters/environmentDetails';
import foodFilter from './modules/filters/food';
import foodDetailsFilter from './modules/filters/foodDetails';
import miningFilter from './modules/filters/mining';
import miningDetailsFilter from './modules/filters/miningDetails';
import healthSecurityFilter from './modules/filters/healthSecurity';
import healthSecurityDetailsFilter from './modules/filters/healthSecurityDetails';
import alertSettingsParamsFilter from './modules/filters/alerts/alertSettingsParams';
import alertSettingsSampleFilter from './modules/filters/alerts/alertSettingsSample';
import alertReportParamsFilter from './modules/filters/alerts/alertReportParams';
import alertReportSampleFilter from './modules/filters/alerts/alertReportSample';
import notificationsDetailFilter from './modules/filters/notificationsDetail';
import riskAnalysisFilter from './modules/filters/riskAnalysis';
import foodStatsFilter from './modules/filters/foodStats';
import advancedServicesFilter from './modules/filters/advancedServices';
import weatherGraphFilter from './modules/filters/weatherGraph';
import hidrosoph from './modules/hidrosoph';
import gisComparatorFilter from './modules/filters/gisComparator';
import agronomyNutritionalMonitoringFilter from './modules/filters/agronomyNutritionalMonitoring';
import expertSystemFilter from './modules/filters/expertSystemFilter';
import geotoolbox from './modules/filters/geotoolbox';
// samples
import homeSamples from './modules/samples/home';
import agronomySamples from './modules/samples/agronomy';
import environmentSamples from './modules/samples/environment';
import foodSamples from './modules/samples/food';
import miningSamples from './modules/samples/mining';
import healthSecuritySamples from './modules/samples/healthSecurity';

// alerts
import alerts from './modules/alerts/alerts';
import alertsParams from './modules/alerts/params';
import alertsSample from './modules/alerts/sample';
import notifications from './modules/notifications';

import PersistentStore from './PersistentStore';
import PersistentStoreDexie from './PersistentStoreDexie';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    company,
    samples,
    filter,
    navbar,
    homeFilter,
    agronomyFilter,
    expertSystemFilter,
    environmentFilter,
    environmentDetailsFilter,
    foodFilter,
    foodDetailsFilter,
    miningFilter,
    miningDetailsFilter,
    healthSecurityFilter,
    healthSecurityDetailsFilter,
    homeSamples,
    agronomySamples,
    environmentSamples,
    foodSamples,
    miningSamples,
    healthSecuritySamples,
    documentation,
    clientMenu,
    contact,
    alerts,
    leftPanel,
    alertsParams,
    alertsSample,
    notifications,
    alertSettingsParamsFilter,
    alertSettingsSampleFilter,
    alertReportParamsFilter,
    alertReportSampleFilter,
    notificationsDetailFilter,
    riskAnalysisFilter,
    riskAnalysis,
    foodStatsFilter,
    foodStats,
    environmentStats,
    gis,
    advancedServicesFilter,
    gisEditor,
    registerFarm,
    advancedServices,
    weather,
    weatherGraphFilter,
    nutritionalFollowing,
    gisComparator,
    nutritionalMonitoring,
    phitomonitoringPopup,
    hidrosoph,
    gisComparatorFilter,
    breadcrumb,
    agronomyNutritionalMonitoringFilter,
    geotoolbox,
  },
  plugins: [
    PersistentStore({
      moduleName: 'user',
      localStorageName: 'user',
    }),
    PersistentStore({
      moduleName: 'company',
      localStorageName: 'company',
    }),
    PersistentStore({
      moduleName: 'contact',
      localStorageName: 'contact',
    }),
    // PersistentStore({
    //   moduleName: 'clientMenu',
    //   localStorageName: 'clientMenu',
    // }),
    PersistentStoreDexie({
      moduleName: 'clientMenu',
      localStorageName: 'clientMenu',
    }),
  ],
});
store.dispatch('user/initialize');

export default store;
