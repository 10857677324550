<template>
  <f7-navbar :sliding="false" class="no-hairline" :class="{ navBarClosed: barIsClosed }">
    <div style="display: flex; align-items: center">
      <f7-link panel-toggle="left" @click="toggleBar">
        <div class="icon-hamburguer" />
      </f7-link>
      <div class="title">
        <p class="title-nav">
          <span v-for="(item, index) in items" :key="index" @click="() => goToRoute(item)">
            <span :class="{
              'hoverable-item': !item.disabled,
            }">
              <strong v-if="item.active">{{ item.name }}</strong>
              <span v-else>{{ item.name }}</span>
            </span>
            <span v-if="items[index + 1] !== undefined">> </span>
          </span>
        </p>
        <!--<p
          v-if="currentRoute ? currentRoute.includes('>') : false"
          class="title-nav"
        >
          {{ currentRouteFormatted }} >
          <strong class="title-nav">{{ currentHighlighted }}</strong>
        </p>
        <p v-else class="title-nav">
          <strong class="item-title-bold">{{
            currentRouteFormatted
          }}</strong>
        </p>-->
      </div>
    </div>

    <f7-nav-right class="nav-right">
      <ClientMenu v-if="isUserLoggedIn" />
      <span v-if="enabled" class="menu-sep" />

      <div v-if="enabled" id="notificationsBar" style="margin-right: 10px">
        <DxButton id="btnNotifications" :type="pendingNotifications ? 'danger' : 'normal'" :styling-mode="pendingNotifications ? 'contained' : 'outlined'
          " @click="toggleNotificationsPopover">
          <i class="f7-icons standardIconWidth">bell</i>
        </DxButton>

        <SummaryPopover v-if="showPopover" />
      </div>

      <f7-link id="user" @click="togglePopOver">
        <f7-icon v-if="!enabled || (enabled && !userProfile.avatar)" slot="media"
          class="navbar__icon accordion__icon--user"></f7-icon>
        <Avatar v-if="enabled && userProfile.avatar" :size="35" :src="userProfile.avatar" />
      </f7-link>

      <div class="test">
        <DxPopover :width="200" :visible="showPopOver" target="#user" position="top">
          <f7-list class="list no-hairlines no-margin">

            <f7-list-item v-if="enabled" link="/profile/" transition="f7-fade" popover-close :title="$t('profile')"
              @click="togglePopOver">
              <f7-icon slot="media" class="color-primary" material="person"></f7-icon>
            </f7-list-item>

            <f7-list-item v-if="disabled && isAdmin" link="/users/" transition="f7-fade" popover-close
              :title="$t('users_management')" @click="togglePopOver">
              <f7-icon slot="media" class="color-primary" material="supervisor_account"></f7-icon>
            </f7-list-item>

            <f7-list-item link="#" popover-close :title="$t('prevBesafer')" @click="onOpenPrevBesafer">
            </f7-list-item>

            <f7-list-item link="#" popover-close :title="$t('supportCenter')" @click="onOpenSupportHandler">
              <f7-icon slot="media" class="color-primary" material="email"></f7-icon>
            </f7-list-item>

            <f7-list-item link="#" popover-close :title="$t('log_out')" @click="closeSession">
              <f7-icon slot="media" class="color-primary" material="exit_to_app"></f7-icon>
            </f7-list-item>

          </f7-list>
        </DxPopover>
      </div>
    </f7-nav-right>
  </f7-navbar>
</template>

<script>
import { DxPopover } from 'devextreme-vue/popover';

import { APP_NAME, ALL_LANGUAGES } from '@/js/constants';
import { mapGetters, mapState, mapActions } from 'vuex';
import Avatar from 'vue-avatar';
import DxButton from 'devextreme-vue/button';
import SummaryPopover from '@/components/Notifications/SummaryPopover/index.vue';
import { defaultLocale } from '@/i18n/index';
import ClientMenu from '../ClientMenu/index.vue';

export default {
  name: 'Navbar',
  components: {
    DxPopover,
    ClientMenu,
    Avatar,
    DxButton,
    SummaryPopover,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    socket: {
      type: WebSocket,
      default: null,
    },
  },
  data() {
    return {
      barIsClosed: false,
      APP_NAME,
      disabled: false,
      showPopOver: false,
    };
  },
  computed: {
    ...mapGetters('user', ['isAdmin', 'userProfile', 'getLanguage']),
    ...mapState('user', ['isUserLoggedIn']),
    ...mapState('contact', ['enabled']),
    ...mapState('navbar', ['showPopover']),
    ...mapState('contact', ['enabled']),
    ...mapState('notifications', ['pendingNotifications']),
    ...mapState('breadcrumb', ['items']),
  },
  methods: {
    onOpenSupportHandler() {
      let mail = '';

      if (this.getLanguage === 'es') {
        mail = 'atencioncliente.esp@agqlabs.com';
      } else if (this.getLanguage === 'en') {
        mail = 'info@agqlabs.com';
      } else if (this.getLanguage === 'it') {
        mail = 'italia@agqlabs.com';
      } else if (this.getLanguage === 'pt') {
        mail = 'infoportugal@agqlabs.com';
      }

      window.open(`mailto:${mail}`, '_blank');
    },
    goToRoute(item) {
      if (item.disabled) return;

      this.$f7.views.main.router.navigate(
        { name: item.page },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    closeSession() {
      this.togglePopOver();
      this.logOut();
      if (this.socket) {
        this.socket.close();
      }

      this.setPreferedLanguage();
    },
    onOpenPrevBesafer() {
      window.open('https://prev.besafer.info/', '_blank');
    },
    isLanguageValid(language) {
      let valid = false;
      for (const lang in ALL_LANGUAGES) {
        if (ALL_LANGUAGES[lang].value === language) {
          valid = true;
        }
      }

      return valid;
    },
    setPreferedLanguage() {
      const navigatorLanguage = navigator.language
        .substring(0, 2)
        .toLowerCase();

      setTimeout(() => {
        this.$i18n.i18next.changeLanguage(
          this.isLanguageValid(navigatorLanguage)
            ? navigatorLanguage
            : defaultLocale
        );
      }, 0);
    },
    togglePopOver() {
      this.showPopOver = !this.showPopOver;
    },
    toggleNotificationsPopover() {
      this.setShowPopover(true);
    },
    toggleBar() {
      this.barIsClosed = !this.barIsClosed;
    },
    ...mapActions('user', ['logOut']),
    ...mapActions('navbar', ['setShowPopover']),
  },
};
</script>

<style lang="scss" scoped>
@import './Navbar.styles.scss';
</style>
