<template>
  <div>
    <div
      class="filterButtonPosition"
      style="display: flex; align-items: center; gap: 0.4rem"
    >
      <f7-button
        fill
        class="whiteFontColor button-filter"
        panel-toggle="right"
      >
        <span class="ic-filter"></span> {{ $t('filter') }}
      </f7-button>
      <ContextMenu :id="'context-menu-btn'" :items="getItems" />
    </div>
    <DxDataGrid
      :data-source="store"
      :show-borders="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-colon="false"
      :remote-operations="true"
      :hover-state-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :no-data-text="$t('noDataTextSummary')"
      @exporting="onExporting"
      @cell-prepared="onCellPrepared"
      @context-menu-preparing="addMenuItems"
      @content-ready="init"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxScrolling mode="standard" />
      <DxStateStoring
        :enabled="true"
        type="custom"
        storage-key="foodSamplesTable"
        :custom-load="loadState"
        :custom-save="customSave"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[5, 10, 20]"
        :show-info="true"
        info-text="{2} items"
        :visible="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('searchSampleCode')"
      />

      <DxExport :enabled="false" />

      <DxColumn
        data-field="summary_semaphore"
        caption=""
        header-cell-template="cellTemplateHeaderDots"
        :width="55"
        :allow-sorting="true"
        cell-template="cellTemplateDots"
        :fixed="true"
        fixed-position="left"
      />
      <DxColumn
        data-field="code"
        :caption="$t('agronomySample.summary.sampleCode')"
        alignment="left"
        :allow-header-filtering="false"
        :fixed="true"
        fixed-position="left"
      />
      <DxColumn
        data-field="sample_type"
        :caption="$t('agronomySample.summary.sampleType')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="sample_name"
        :caption="$t('agronomySample.summary.description')"
        :width="300"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="reception_date"
        :caption="$t('foodSamples.receptionDate')"
        alignment="left"
        data-type="date"
        format="dd/MM/yyyy"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="end_date"
        :caption="$t('foodSamples.endDate')"
        alignment="left"
        data-type="date"
        format="dd/MM/yyyy"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="analyse_type"
        :caption="$t('foodSamples.analysisType')"
        alignment="left"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field="cultive_organic"
        :caption="$t('foodSamples.ecologic')"
        alignment="left"
        :allow-header-filtering="false"
        cell-template="cellTemplateOrganicOrNot"
      />
      <DxColumn
        data-field="third_client"
        :caption="$t('foodSamples.thirdClient')"
        alignment="left"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field="n_superior_cmas"
        :caption="$t('foodSamples.n_superior_cmas')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="n_superior_lmrs"
        :caption="$t('foodSamples.n_superior_lmrs')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="n_positives"
        :caption="$t('foodSamples.noOfPositives')"
        alignment="left"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field="n_traces"
        :caption="$t('foodSamples.noOfTraces')"
        alignment="left"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field="study"
        :caption="$t('environmentSamples.summary.project')"
        alignment="left"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field="farm"
        :caption="$t('foodSamples.samplingSite')"
        alignment="left"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field="parcel"
        :caption="$t('environmentSamples.samplingPoint')"
        alignment="left"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field="status"
        :caption="$t('agronomySample.summary.status')"
        alignment="left"
        :width="150"
        :fixed="true"
        fixed-position="right"
        :allow-header-filtering="false"
        cell-template="cellTemplateStatus"
      />

      <DxColumn
        :buttons="updatedEditButtons"
        :fixed="true"
        fixed-position="right"
        type="buttons"
        width="80"
      />

      <DxColumn
        v-for="chain in selectedEuropeanChains"
        :key="chain"
        :name="chain"
        :caption="chain"
        cell-template="cellTemplateChain"
      />
      <template #cellTemplateChain="{ data }">
        <div>
          <div
            v-if="
              data.data.retailers && data.data.retailers.length > 0
            "
          >
            <div
              v-if="
                data.data.retailers
                  .flatMap((el) => el.Supermercado)
                  .includes(data.column.caption)
              "
            >
              <div style="text-align: center">
                <img
                  width="20"
                  height="20"
                  :src="
                    getDotColorImage(
                      data.data.retailers.find(
                        (el) =>
                          el.Supermercado === data.column.caption
                      ).Confirmado
                        ? 'green'
                        : data.data.retailers.find(
                            (el) =>
                              el.Supermercado === data.column.caption
                          ).Confirmado === null ||
                          data.data.value_report === 'CMA'
                        ? 'grey'
                        : 'red'
                    )
                  "
                />
              </div>
            </div>
          </div>
          <div v-else style="text-align: center">
            <img
              width="20"
              height="20"
              :src="getDotColorImage('grey')"
            />
          </div>
        </div>
      </template>
      <template #cellTemplateOrganicOrNot="{ data }">
        <div v-if="data.value">{{ $t('yes') }}</div>
        <div v-else>{{ $t('no') }}</div>
      </template>
      <template #cellTemplateDots="{ data }">
        <div class="dots-parent-container">
          <img
            width="20"
            height="20"
            :src="getDotColorImage(data.data.summary_semaphore)"
          />
        </div>
      </template>
      <template #cellTemplateStatus="{ data }">
        <div
          :class="$helpers.getStatusClass(data.data.status_code)"
          style="text-align: center"
        >
          {{ data.data.status }}
        </div>
      </template>
      <template #cellTemplateHeaderDots="{ data }">
        <div>
          <PopUp position="top">
            <div style="padding: 10px">
              <div class="pop-up-info">
                <img
                  :src="getDotColorImage('grey')"
                  width="15"
                  height="15"
                />
                <div style="padding-left: 5px">
                  {{ $t('foodSamples.summary.greyDotDescription') }}
                </div>
              </div>
              <div class="pop-up-info">
                <img
                  :src="getDotColorImage('green')"
                  width="15"
                  height="15"
                />
                <div style="padding-left: 5px">
                  {{ $t('foodSamples.summary.greenDotDescription') }}
                </div>
              </div>
              <div class="pop-up-info">
                <img
                  :src="getDotColorImage('orange')"
                  width="15"
                  height="15"
                />
                <div style="padding-left: 5px">
                  {{ $t('foodSamples.summary.orangeDotDescription') }}
                </div>
              </div>
              <div class="pop-up-info">
                <img
                  :src="getDotColorImage('red')"
                  width="15"
                  height="15"
                />
                <div style="padding-left: 5px">
                  {{ $t('foodSamples.summary.redDotDescription') }}
                </div>
              </div>
            </div>
          </PopUp>
        </div>
      </template>
    </DxDataGrid>

    <!-- <ContextMenu :id="'context-menu-btn'" :items="getItems" /> -->

    <NotificationSpinner
      v-if="notificationMessage.length > 0"
      :message="notificationMessage"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxStateStoring,
  DxPager,
  DxPaging,
  DxScrolling,
  DxExport,
} from 'devextreme-vue/data-grid';

import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { trigger } from 'devextreme/events';
import PopUp from '@/components/UtilityComponents/popUpInfo/popUp.vue';
import {
  ALLOWED_SAMPLES_PARAMETERS,
  ATCS_ES,
  NOTIFY_TIME_LONG,
} from '@/js/constants';
import ContextMenu from '@/components/contextMenu/index.vue';
import NotificationSpinner from '@/components/NotificationSpinner';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxStateStoring,
    DxPager,
    DxPaging,
    DxScrolling,
    DxExport,
    PopUp,
    ContextMenu,
    NotificationSpinner,
  },
  props: {
    store: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editButtons: [
        {
          icon: 'find',
          onClick: this.viewAtc,
        },
        {
          icon: 'download',
          onClick: this.triggerContextualMenu,
        },
      ],
      itemsDisabled: [
        {
          icon: 'excel',
          text: this.$t('excelDownload'),
          onItemClick: () => {
            this.onExporting(this.grid);
          },
        },
        {
          icon: 'excel',
          text: this.$t('excelDownloadParameters'),
          onItemClick: () => {
            // this.onExporting(this.grid);
          },
          disabled: true,
        },
        {
          icon: 'pdf',
          text: this.$t('pdfReportDownload'),
          onItemClick: () => {
            // this.onExporting(this.grid);
          },
          disabled: true,
        },
      ],
      itemsEnabled: [
        {
          icon: 'excel',
          text: this.$t('excelDownload'),
          onItemClick: () => {
            this.onExporting(this.grid);
          },
        },
        {
          icon: 'excel',
          text: this.$t('excelDownloadParameters'),
          onItemClick: async () => {
            try {
              this.setDisableContext(true);
              this.notificationMessage = this.$t('downloading');

              const samples = await this.getSamplesWithParameters();
              this.formatExcel(samples);
            } catch (e) {
              this.notificationMessage = '';
              this.$notifyDX(
                {
                  message: this.$t('downloadError'),
                  width: 350,
                },
                'error',
                NOTIFY_TIME_LONG
              );
            } finally {
              this.setDisableContext(false);

              setTimeout(() => {
                this.notificationMessage = '';
              }, 2000);
            }
          },
        },
        {
          icon: 'pdf',
          text: this.$t('pdfReportDownload'),
          onItemClick: async () => {
            this.setDisableContext(true);

            try {
              this.notificationMessage = this.$t('downloading');

              const b64 = await this.getSamplesZip();

              const link = document.createElement('a');
              link.href = `data:application/octet-stream;base64,${b64}`;
              link.download = `besafer-reports-${new Date().toLocaleString()}.zip`;
              link.click();

              URL.revokeObjectURL(link.href);

              this.notificationMessage = this.$t('downloadedFile');
            } catch (e) {
              this.notificationMessage = '';
              this.$notifyDX(
                {
                  message: this.$t(
                    this.$helpers.getFilteredErrorMessage(
                      JSON.parse(e.message).error
                    )
                  ),
                  width: 550,
                },
                'error',
                NOTIFY_TIME_LONG
              );
            } finally {
              setTimeout(() => {
                this.notificationMessage = '';
              }, 2000);
              this.setDisableContext(false);
            }
          },
        },
      ],
      grid: null,
      notificationMessage: '',
    };
  },
  computed: {
    getItems() {
      return this.disableContext
        ? this.itemsDisabled
        : this.itemsEnabled;
    },
    ...mapState('samples', ['disableContext', 'isDownloadingReport']),
    ...mapState('foodFilter', ['selectedEuropeanChains']),
    updatedEditButtons() {
      return this.isDownloadingReport
        ? [
            {
              icon: 'find',
              onClick: this.viewAtc,
            },
            {
              icon: 'download',
              onClick: this.download,
              disabled: true,
            },
          ]
        : [
            {
              icon: 'find',
              onClick: this.viewAtc,
            },
            {
              icon: 'download',
              onClick: this.triggerContextualMenu,
              disabled: false,
            },
          ];
    },
  },
  beforeMount() {
    this.setIsDownloadingReport(false);
    this.setDisableContext(false);
    this.setAllFilterParams({ atc_name: ATCS_ES.food });
  },
  methods: {
    loadState() {
      let state = localStorage.getItem('foodSamplesTable');
      if (state) {
        state = JSON.parse(state);
        state.searchText = '';
      }
      return state;
    },
    customSave(state) {
      localStorage.setItem('foodSamplesTable', JSON.stringify(state));
    },
    getDotColorImage(color) {
      return require(`../../../../assets/dot-colors/${color}.svg`)
        .default;
    },
    formatExcel(samples) {
      if (samples.length > ALLOWED_SAMPLES_PARAMETERS) {
        this.notificationMessage = this.$t(
          'sampleParametersMustFilter'
        );
        this.setDisableContext(false);

        return;
      }
      const config = [
        {
          sheet: 'Samples',
          columns: [
            {
              label: this.$t('agronomySample.summary.sampleCode'),
              value: 'code',
            },
            {
              label: this.$t('agronomySample.summary.sampleType'),
              value: 'sample_type',
            },
            {
              label: this.$t('agronomySample.summary.description'),
              value: 'sample_name',
            },
            {
              label: this.$t('agronomySample.summary.lot_code'),
              value: 'lot_code',
            },
            {
              label: this.$t('foodSamples.receptionDate'),
              value: 'reception_date',
            },
            {
              label: this.$t('foodSamples.endDate'),
              value: 'end_date',
            },
            {
              label: this.$t('foodSamples.analysisType'),
              value: 'analyse_type',
            },
            {
              label: this.$t('foodSamples.ecologic'),
              value: 'cultive_organic',
            },
            {
              label: this.$t('foodSamples.thirdClient'),
              value: 'third_client',
            },
            {
              label: this.$t('foodSamples.n_superior_cmas'),
              value: 'food_aggregated_info.n_superior_cmas',
            },
            {
              label: this.$t('foodSamples.n_superior_lmrs'),
              value: 'food_aggregated_info.n_superior_lmrs',
            },
            {
              label: this.$t('foodSamples.noOfPositives'),
              value: 'food_aggregated_info.n_positives',
            },
            {
              label: this.$t('foodSamples.noOfTraces'),
              value: 'food_aggregated_info.n_traces',
            },
            {
              label: this.$t('environmentSamples.summary.project'),
              value: 'study',
            },
            {
              label: this.$t('foodSamples.samplingSite'),
              value: 'farm',
            },
            {
              label: this.$t('environmentSamples.samplingPoint'),
              value: 'parcel',
            },
            {
              label: this.$t('agronomySample.summary.status'),
              value: 'status',
            },
          ],
          content: [
            ...samples.map((sample) => {
              sample.parameters.forEach((parameter) => {
                sample.sample[
                  `${parameter.parameter} ${parameter.unit}`
                ] = parameter.value;
              });

              return sample.sample;
            }),
          ],
        },
      ];

      samples.forEach((sample) => {
        sample.parameters.forEach((parameter) => {
          config[0].columns.push({
            label: `${parameter.parameter} ${parameter.unit}`,
            // value: `${parameter.parameter} ${parameter.unit}`,
            value: (data) =>
              data[`${parameter.parameter} ${parameter.unit}`],
          });
        });
      });

      const fileSettings = {
        fileName: `besafer-samples-parameters-${this.$helpers.getDateFormatedDayMonthYear(
          new Date()
        )}`,
        extraLength: 3,
        writeOptions: {},
      };

      this.$jsonToXLSX(config, fileSettings, () => {
        this.setDisableContext(false);

        this.notificationMessage = this.$t(
          'xslxDownloadedSuccessfully'
        );
      });
    },
    onToolbarPreparing(e) {
      // e.toolbarOptions.items.unshift({
      //   location: 'after',
      //   widget: 'dxButton',
      //   options: {
      //     icon: 'chevrondown',
      //     elementAttr: {
      //       id: 'context-menu-btn',
      //     },
      //     onClick: ({ event }) => {
      //       event.preventDefault();
      //     },
      //   },
      // });
    },
    init(e) {
      this.setSamples(e.component.getDataSource().items());
      this.grid = e;
    },
    triggerContextualMenu(e) {
      this.setReportSampleId(e.row.data.sample_id);

      const dxContextMenuEvent = {
        ...e.event,
        type: 'dxcontextmenu',
      };
      e.event.stopPropagation();

      trigger(
        e.row.cells[e.row.cells.length - 1].cellElement,
        dxContextMenuEvent
      );
    },
    viewAtc(e) {
      this.setSampleFetchedFromEtl(false);
      this.setLastRouteHome(false);

      this.setCurrentSampleId(e.row.data.id);
      this.setReportSampleId(e.row.data.sample_id);
      this.setCurrentFilter('foodDetails');
      this.setCurrentSample(e.row.data);
      this.setIsNoRp(e.row.data.value_report === 'CMA');
      this.setSelectedLimits([this.$t('positives')]);
      this.setReportSampleCode(e.row.data.code);

      if (e.row.data.value_report !== 'CMA') {
        if (e.row.data.retailers) {
          const chains = [...e.row.data.retailers];

          const res = [];

          chains.forEach((chainData) => {
            chainData.Reglas.forEach((chain) => {
              const reglaFormatted = chain.Regla.includes('.')
                ? chain.Regla.split('.').join('')
                : chain.Regla;
              if (
                res.length > 0 &&
                res.some(
                  (el) => el.Supermercado === chainData.Supermercado
                )
              ) {
                const indexRes = res.findIndex(
                  (el) => el.Supermercado === chainData.Supermercado
                );

                res[indexRes] = {
                  ...res[indexRes],
                  [`Regla ${chain.Regla}`]: chain.Regla,
                  [`Resultado ${this.$t(
                    `chainsList.${reglaFormatted}`
                  )}`]: chain.Resultado,
                  [`CodRegla ${chain.Regla}`]: chain.CodRegla,
                  Supermercado: chainData.Supermercado,
                  Confirmado: chainData.Confirmado,
                };
              } else {
                res.push({
                  [`Resultado ${this.$t(
                    `chainsList.${reglaFormatted}`
                  )}`]: chain.Resultado,
                  [`Regla ${chain.Regla}`]: chain.Regla,
                  [`CodRegla ${chain.Regla}`]: chain.CodRegla,
                  Supermercado: chainData.Supermercado,
                  Confirmado: chainData.Confirmado,
                  ...chain,
                });
              }
            });
          });

          this.setEuropeanChains(res);
        } else {
          this.setEuropeanChains([]);
        }
      }

      this.replaceCurrent({
        name: this.$t('sampleSummary'),
        disabled: true,
        page: 'FoodSampleDetailPage',
        active: true,
      });

      this.$f7router.navigate(
        { name: 'FoodSampleDetailPage' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
      this.applyCurrentFilters();
    },
    addMenuItems(e) {
      if (e.column.name !== 'buttons') {
        return;
      }
      if (e.target === 'content') {
        if (!e.items) e.items = [];

        e.items.push({
          icon: 'pdffile',
          text: this.$t('generalReport'),
          onItemClick: async () => {
            try {
              this.setIsDownloadingReport(true);
              this.notificationMessage = this.$t('downloading');

              const report = await this.getReport();

              if (report.name && report.pdfB64) {
                const link = document.createElement('a');
                link.href = `data:application/octet-stream;base64,${report.pdfB64}`;
                link.download = report.name;
                link.click();

                URL.revokeObjectURL(link.href);

                this.notificationMessage = this.$t('downloadedFile');
              } else {
                this.notificationMessage = '';
                this.$notifyDX(
                  {
                    message: this.$t('sampleHasNoReport'),
                    width: 450,
                  },
                  'info',
                  2000
                );
              }
            } catch (error) {
              this.$notifyDX(
                {
                  message: this.$t('reportNotAvailable'),
                  width: 450,
                },
                'error',
                NOTIFY_TIME_LONG
              );
            } finally {
              setTimeout(() => {
                this.notificationMessage = '';
              }, 2000);
              this.setIsDownloadingReport(false);
            }
          },
        });

        e.items.push({
          icon: 'pdffile',
          text: this.$t('tracesReport'),
          onItemClick: async () => {
            try {
              const DOC_TYPE_TRACES = 2;
              this.setIsDownloadingReport(true);
              this.notificationMessage = this.$t('downloading');

              const report = await this.getReport(DOC_TYPE_TRACES);

              if (report.name && report.pdfB64) {
                const link = document.createElement('a');
                link.href = `data:application/octet-stream;base64,${report.pdfB64}`;
                link.download = report.name;
                link.click();

                URL.revokeObjectURL(link.href);

                this.notificationMessage = this.$t('downloadedFile');
              } else {
                this.notificationMessage = '';
                this.$notifyDX(
                  {
                    message: this.$t('sampleHasNoReport'),
                    width: 450,
                  },
                  'info',
                  2000
                );
              }
            } catch (error) {
              this.$notifyDX(
                {
                  message: this.$t('reportNotAvailable'),
                  width: 450,
                },
                'error',
                NOTIFY_TIME_LONG
              );
            } finally {
              setTimeout(() => {
                this.notificationMessage = '';
              }, 2000);
              this.setIsDownloadingReport(false);
            }
          },
          disabled: !e.row.data?.traces_report,
        });
      }
    },
    onCellPrepared(e) {
      if (e.rowType === 'data') {
        if (!e.data.seen) {
          e.cellElement.classList.add('hasntBeenSeen');
        }
      }
    },
    onExporting(e) {
      e.component.beginUpdate();
      e.component.columnOption('ID', 'visible', true);
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(
        `${`${this.$t(
          'environmentSamples.exportExcelSampleTable'
        )}-${this.$t('Food')}`}`
      );

      exportDataGrid({
        component: e.component,
        worksheet,
      })
        .then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], {
                type: 'application/octet-stream',
              }),
              `besafer-samples-${this.$helpers.getDateFormatedDayMonthYear(
                new Date()
              )}.xlsx`
            );
          });
        })
        .then(() => {
          e.component.columnOption('ID', 'visible', false);
          e.component.endUpdate();
        });

      e.cancel = true;
    },
    ...mapActions('filter', [
      'setCurrentFilter',
      'applyCurrentFilters',
      'setCurrentSampleId',
    ]),
    ...mapActions('foodSamples', [
      'setSamples',
      'setIsNoRp',
      'setEuropeanChains',
    ]),
    ...mapActions('samples', [
      'setReportSampleId',
      'getReport',
      'setCurrentSample',
      'setReportSampleCode',
      'setDisableContext',
      'getSamplesWithParameters',
      'setAllFilterParams',
      'getSamplesZip',
      'setIsDownloadingReport',
      'setSampleFetchedFromEtl',
      'setLastRouteHome',
    ]),
    ...mapActions('foodDetailsFilter', ['setSelectedLimits']),
    ...mapActions('breadcrumb', ['replaceCurrent']),
  },
};
</script>
