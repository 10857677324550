import { ALL_LANGUAGES, RELEASE_VERSION } from '@/js/constants';
import Request from './Request';

import EventBus from './event-bus';

let headerAuth = '';
const baseUrlGis = 'https://gis.hispatecanalytics.com'; // Endpoint preproducción Geoserver

const baseUrl = 'https://besafer.privatecloud.hispatecanalytics.com'; // Endpoint estable pro
const geoServerWorkspace = 'besafer';

const geoServerProjectWorkSpace = `${baseUrlGis}/geoserver/${geoServerWorkspace}/wms?`;
const geoServerProjectWorkSpaceInterpol = `${baseUrlGis}/geoserver/int_results/wms?`;
const geoServerRasterDates = `${baseUrlGis}/gisw/ws/get_raster_dates/`;
const geoServerGetFeatureInt = `${baseUrlGis}/gisw/ws/getfeature_int/`;
const geoServer = `${baseUrlGis}/gisw/ws`;
const geoServerPort = `${baseUrlGis}:8443/geoserver`;
const geoServerIndexStats = `${baseUrlGis}/gisw/ws/get_index_stats/`;
const geoServerBesafer = `${baseUrlGis}/geoserver/${geoServerWorkspace}/wms?`;
const geoServerFeatureInfo = `${baseUrlGis}/gisw/ws/getfeatureinfo/`;
const geoServerSigpacMap = `${baseUrlGis}/gisw/ws/getmap/`;
const geoServerGetraster = `${baseUrlGis}/gisw/ws/getraster`;

EventBus.$on('newLoggedUser', (payload) => {
  headerAuth = `Bearer ${payload}`;
});

export default {
  getGeoServerSigpacMap() {
    return geoServerSigpacMap;
  },
  getGeoServer() {
    return geoServer;
  },
  getGeoServerIndexStats() {
    return geoServerIndexStats;
  },
  getGeoServerBesafer() {
    return geoServerBesafer;
  },
  getGeoServerWorkspace() {
    return geoServerWorkspace;
  },
  getGeoServerProjectWorkSpace() {
    return geoServerProjectWorkSpace;
  },
  getGeoServerProjectWorkSpaceInterpol() {
    return geoServerProjectWorkSpaceInterpol;
  },
  getGeoServerRasterDates() {
    return geoServerRasterDates;
  },
  getBaseUrlGis() {
    return baseUrlGis;
  },
  getGeoServerPort() {
    return geoServerPort;
  },
  getGeoServerFeatureInfo() {
    return geoServerFeatureInfo;
  },
  getGeoServerGetraster() {
    return geoServerGetraster;
  },
  login(credentials) {
    const url = `${baseUrl}/user/authorization`;
    const payload = {
      data: JSON.stringify({
        email: credentials.username,
        password: credentials.password,
      }),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json'
    ).then(this.handleLoginResult);
  },
  getUser(companyId, addressCode) {
    const url = `${baseUrl}/user`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getHomeSamples(params, companyId, addressCode) {
    const url = `${baseUrl}/samples/${params}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getAgronomySamples(params, companyId, addressCode) {
    const url = `${baseUrl}/samples/agronomy/${params || ''}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getAgronomySamplesWithFilters(params, companyId, addressCode) {
    const url = `${baseUrl}/samples/agronomy/`;
    const payload = {
      data: params,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getHomeSamplesWithFilters(params, companyId, addressCode) {
    const url = `${baseUrl}/samples/`;
    const payload = {
      data: params,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSamplesDetailsWithFilters(params, id, companyId, addressCode) {
    const url = `${baseUrl}/samples/${id}/get_parameters/`;
    const payload = {
      data: params,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getEnvironmentSamplesWithFilters(params, companyId, addressCode) {
    const url = `${baseUrl}/samples/environment/`;
    const payload = {
      data: params,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getMiningSamplesWithFilters(params, companyId, addressCode) {
    const url = `${baseUrl}/samples/minery/`;
    const payload = {
      data: params,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getAggregateInfo(companyId, addressCode) {
    const url = `${baseUrl}/samples/aggregated_information/`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSampleDetails(id, params, type = '', companyId, addressCode) {
    let url;

    if (type === 'agronomy') {
      url = `${baseUrl}/samples/${id}/get_parameters/`;
    } else {
      url = `${baseUrl}/samples/${id}/get_parameters/${params ?? ''}`;
    }

    return Request.async(
      url,
      {},
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getFoodSamples(params, companyId, addressCode) {
    const url = `${baseUrl}/samples/food/${params || ''}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getMiningSamples(params, companyId, addressCode) {
    const url = `${baseUrl}/samples/minery/${params || ''}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getFoodSamplesWithFilters(params, companyId, addressCode) {
    const url = `${baseUrl}/samples/food/`;
    const payload = {
      data: params,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getEnvironmentSamples(params, companyId, addressCode, lang) {
    const url = `${baseUrl}/samples/environment/`;
    const payload = {
      data: params,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  translateLanguage(value) {
    let key = '';
    for (const lang in ALL_LANGUAGES) {
      if (ALL_LANGUAGES[lang].value === value) {
        key = ALL_LANGUAGES[lang].key;
      }
    }

    return key;
  },
  getHealthAndSecuritySamples(params, companyId, addressCode) {
    const url = `${baseUrl}/samples/health_and_security/${
      params || ''
    }`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getHealthAndSecuritySamplesWithFilters(
    params,
    companyId,
    addressCode
  ) {
    const url = `${baseUrl}/samples/health_and_security/`;
    const payload = {
      data: params,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getDetalledNutritionalMonitoring(params, companyId, addressCode) {
    const url = `${baseUrl}/samples/get_detalled_nutritional_monitoring/`;
    const payload = {
      data: params,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getPhitomonitoringOptions(params, companyId, addressCode) {
    const url = `${baseUrl}/phitomonitoring_options/`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getPhitomonitoringData(params, companyId, addressCode) {
    const url = `${baseUrl}/phitomonitoring/`;
    const payload = {
      data: params,
    };
    // const payload = {
    //   data: null,
    // };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  createPhitomonitoringData(params, companyId, addressCode) {
    const url = `${baseUrl}/phitomonitoring/`;
    const payload = {
      data: JSON.stringify({ ...params }),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  editPhitomonitoringData(params, companyId, addressCode) {
    const url = `${baseUrl}/phitomonitoring/${params.id}/`;
    const payload = {
      data: JSON.stringify({ ...params }),
    };
    return Request.async(
      url,
      payload,
      'PATCH',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  deletePhitomonitoringData(id, companyId, addressCode) {
    const url = `${baseUrl}/phitomonitoring/${id}/`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  updateUserAddresses(id, addressesData, companyId, addressCode) {
    const url = `${baseUrl}/company/user/${id}/address`;

    const payload = {
      data: { address_code: addressesData },
    };
    return Request.async(
      url,
      payload,
      'PUT',
      true,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  deleteUserAddresses(id, addressesData, companyId, addressCode) {
    const url = `${baseUrl}/company/user/${id}/address`;

    fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: headerAuth,
        company: companyId,
        address: addressCode,
        'app-version': RELEASE_VERSION,
      },
      body: JSON.stringify({ address_code: addressesData }),
    })
      .then((res) => {})
      .catch((err) => console.error(err));
  },
  getSampleReport(sampleId, docType = 1) {
    const url = `${baseUrl}/etl/get_file/`;
    const payload = {
      data: JSON.stringify({
        id_sample: sampleId,
        document_type: docType.toString(),
      }),
    };

    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json'
    );
  },
  uploadFile(params, companyId, addressCode) {
    const url = `${baseUrl}/documentation/`;

    const formData = new FormData();
    formData.append('name', params.name);
    formData.append('contract_number', params.contractNumber);
    formData.append('emails', params.emails.toString());
    formData.append('description', params.description);
    formData.append('attached_file', params.file);
    formData.append('atc', params.atc);
    formData.append('size', params.size);
    formData.append('extension', params.extension);
    formData.append('company_code', companyId);
    formData.append('address_code', addressCode);

    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: headerAuth,
        company: companyId,
        address: addressCode,
        'app-version': RELEASE_VERSION,
      },
      body: formData,
    })
      .then((res) => res)
      .catch((err) => console.error(err));
  },
  editFile(params, companyId, addressCode) {
    const url = `${baseUrl}/documentation/${params.id}/`;

    const formData = new FormData();

    formData.append('name', params.name);
    formData.append('contract_number', params.contractNumber);
    formData.append('emails', params.emails.toString());
    formData.append('description', params.description);
    formData.append('company_code', companyId);
    formData.append('address_code', addressCode);

    return fetch(url, {
      method: 'PATCH',
      headers: {
        Authorization: headerAuth,
        company: companyId,
        address: addressCode,
        'app-version': RELEASE_VERSION,
      },
      body: formData,
    })
      .then((res) => res)
      .catch((err) => console.error(err));
  },
  getDocumentationTree(params, companyId, addressCode) {
    const url = `${baseUrl}/documentation/get_tree/`;
    const payload = {
      data: params,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  deleteFile(id, companyId, addressCode) {
    const url = `${baseUrl}/documentation/${id}/`;

    fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: headerAuth,
        company: companyId,
        address: addressCode,
        'app-version': RELEASE_VERSION,
      },
    })
      .then((res) => res)
      .catch((err) => console.error(err));
  },
  getAttributeList(
    atc,
    attribute,
    sampleId = '',
    companyId,
    addressCode
  ) {
    const url = `${baseUrl}/samples/get_attribute_list/`;

    const payload = {
      data: {
        atc,
        attribute,
        sample_id: sampleId,
      },
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getAttributeListGeotoolbox(
    atc,
    attribute,
    sampleId = '',
    sampleTypeForGeometry = null,
    companyId,
    addressCode
  ) {
    const url = `${baseUrl}/samples/get_attribute_list/`;

    const payload = {
      data: {
        atc,
        attribute,
        sample_id: sampleId,
      },
    };

    if (sampleTypeForGeometry !== null) {
      payload.data.sample_type_for_geometry = sampleTypeForGeometry;
    }

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getAttributeListPhytomonitoring(
    atc,
    attribute,
    sampleId = '',
    sampleTypeForGeometry = null,
    companyId,
    addressCode
  ) {
    const url = `${baseUrl}/phitomonitoring/get_attribute_list/`;

    const payload = {
      data: {
        atc,
        attribute: attribute.attribute,
        category_id: attribute.category_id,
        type_id: attribute.type_id,
        sample_id: sampleId,
      },
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSamplesParameterResult(data, companyId, addressCode) {
    const url = `${baseUrl}/samples/get_samples_parameter_result/`;

    const payload = {
      data,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },

  getSamplesFarms(atc, companyId, addressCode) {
    const url = `${baseUrl}/farms/`;
    const payload = {
      data: {
        atc_name: atc,
        company_code: companyId,
      },
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSamplesParcels(atc, farm, companyId, addressCode) {
    const url = `${baseUrl}/farms/parcels/`;
    const payload = {
      data: {
        atc_name: atc,
        farm,
        company_code: companyId,
      },
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSamplesParametersCmaSuperiorAndRmin(
    data,
    companyId,
    addressCode
  ) {
    const url = `${baseUrl}/samples/get_samples_parameters_cma_superior_and_rmin/`;

    const payload = {
      data,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSamplesCount(data, companyId, addressCode) {
    const url = `${baseUrl}/samples/get_samples_with_parameter_count/`;

    const payload = {
      data,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSampleInfo(id, companyId, addressCode) {
    const url = `${baseUrl}/samples/${id}/`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getCmaAptCount(id, companyId, addressCode) {
    const url = `${baseUrl}/samples/${id}/cma_apt_count/`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  updateContact(params, contactCode, companyId, addressCode) {
    const url = `${baseUrl}/contacts/${contactCode}/`;

    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: headerAuth,
        company: companyId,
        address: addressCode,
        'app-version': RELEASE_VERSION,
      },
      body: JSON.stringify({
        name: params.name,
        surname: params.surname,
        language: params.language,
        phone: params.phone,
        company_id: params.companyId,
        addresses: params.addresses,
      }),
    })
      .then((res) => {})
      .catch((err) => console.error(err));
  },
  createContact(params, companyId, addressCode) {
    const url = `${baseUrl}/contacts/`;

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: headerAuth,
        company: companyId,
        address: addressCode,
        'app-version': RELEASE_VERSION,
      },
      body: JSON.stringify({
        name: params.name,
        surname: params.surname,
        email: params.email,
        language: params.language,
        phone: params.phone,
        company_code: params.companyId,
        addresses: params.addresses,
      }),
    })
      .then((res) => res)
      .catch((err) => err);
  },
  getSampleParameterLMRs(id, companyId, addressCode) {
    const url = `${baseUrl}/samples/${id}/get_sample_parameter_lmrs/`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getEntity(
    entity,
    sampleTypeId,
    countryCode,
    companyId,
    addressCode
  ) {
    const url = `${baseUrl}/etl/get_data_entity/`;
    const payload = {
      data: JSON.stringify({
        entity,
        sample_type_id: sampleTypeId,
        country_code: countryCode,
      }),
    };

    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  saveParamsAlert(data, companyId, addressCode) {
    const url = `${baseUrl}/parameter_alerts/`;
    const payload = {
      data: JSON.stringify({
        parameters: data.parameters,
        farm: data.farm,
        parcel: data.parcel,
        atc: data.atc,
        sample_type_id: data.sampleTypeId.id,
        sample_type: data.sampleType,
        is_active: true,
        study: data.study,
        sending_notifications: data.sendingNotifications,
      }),
    };

    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  duplicateParamsAlert(data, companyId, addressCode) {
    const url = `${baseUrl}/parameter_alerts/`;
    const payload = {
      data: JSON.stringify({
        parameters: data.parameters,
        farm: data.farm,
        parcel: data.parcel,
        atc: data.atc,
        sample_type_id: data.sampleTypeId,
        sample_type: data.sampleType,
        is_active: true,
        study: data.study,
        sending_notifications: data.sendingNotifications,
      }),
    };

    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getParamsAlerts(params, companyId, addressCode) {
    const url = `${baseUrl}/parameter_alerts/`;
    const payload = {
      data: params,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  updateAlertState(
    id,
    status,
    companyId,
    addressCode,
    isSampleAlert = false
  ) {
    const url = `${baseUrl}${
      !isSampleAlert ? '/parameter_alerts/' : '/sample_alerts/'
    }${id}/`;
    const payload = {
      data: JSON.stringify({
        is_active: status,
      }),
    };

    return Request.async(
      url,
      payload,
      'PATCH',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  updateParamsAlert(id, data, companyId, addressCode) {
    const url = `${baseUrl}/parameter_alerts/${id}/`;
    const payload = {
      data: JSON.stringify({
        parameters: data.parameters,
        farm: data.farm,
        parcel: data.parcel,
        atc: data.atc,
        sample_type: data.sampleType,
        study: data.study,
        sending_notifications: data.sendingNotifications,
      }),
    };

    return Request.async(
      url,
      payload,
      'PATCH',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  deleteParamsAlert(id, companyId, addressCode) {
    const url = `${baseUrl}/parameter_alerts/${id}/`;
    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  saveSampleAlert(data, companyId, addressCode) {
    let retailerNum;
    if(data.retailers_num !== undefined) {
      retailerNum = data.retailers_num;
    } else {
      retailerNum = data.retailersNum;
    }
    const url = `${baseUrl}/sample_alerts/`;
    const payload = {
      data: JSON.stringify({
        farm: data.farm,
        parcel: data.parcel,
        atc: data.atc,
        sample_type: data.sampleType,
        sample_type_id: data.sampleTypeId,
        sample_name: data.sampleName,
        is_active: data.isActive,
        value_report: data.valueReport,
        n_superior_lmr_parameters: data.nrSuperiorParams,
        study: data.study,
        retailers_num: retailerNum,
        retailers: data.retailers,
        sending_notifications: data.sendingNotifications,
      }),
    };

    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSampleAlerts(params, companyId, addressCode) {
    const url = `${baseUrl}/sample_alerts/`;
    const payload = {
      data: params,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  updateSampleAlert(id, data, companyId, addressCode) {
    const url = `${baseUrl}/sample_alerts/${id}/`;
    const payload = {
      data: JSON.stringify({
        farm: data.farm,
        parcel: data.parcel,
        atc: data.atc,
        sample_type: data.sampleType,
        value_report: data.valueReport,
        n_superior_lmr_parameters: data.nrSuperiorParams,
        retailers_num: data.retailersNum,
        retailers: data.retailers,
        study: data.study,
        sending_notifications: data.sendingNotifications,
      }),
    };

    return Request.async(
      url,
      payload,
      'PATCH',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  deleteSampleAlert(id, companyId, addressCode) {
    const url = `${baseUrl}/sample_alerts/${id}/`;
    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getNotificationsReports(companyId, addressCode, all, params) {
    const url = `${baseUrl}/alert_notifications/${all ? 'all/' : ''}`;
    const payload = {
      data: params,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  deleteNotificationReport(id, companyId, addressCode) {
    const url = `${baseUrl}/alert_notifications/${id}`;
    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getParameterAlertReports(params, companyId, addressCode) {
    const url = `${baseUrl}/parameter_alert_reports/`;
    const payload = {
      data: params,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSampleAlertReports(params, companyId, addressCode) {
    const url = `${baseUrl}/sample_alert_reports/`;
    const payload = {
      data: params,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  deleteParamsAlertReport(id, companyId, addressCode) {
    const url = `${baseUrl}/parameter_alert_reports/${id}/`;
    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  deleteSampleAlertReport(id, companyId, addressCode) {
    const url = `${baseUrl}/sample_alert_reports/${id}/`;
    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getParameterAlertAttributeList(attribute, companyId, addressCode) {
    const url = `${baseUrl}/parameter_alerts/get_attribute_list/`;
    const payload = {
      data: {
        attribute,
      },
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getParameterAlertReportAttributeList(
    attribute,
    companyId,
    addressCode
  ) {
    const url = `${baseUrl}/parameter_alert_reports/get_attribute_list/`;
    const payload = {
      data: {
        attribute,
      },
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSampleAlertReportAttributeList(
    attribute,
    companyId,
    addressCode
  ) {
    const url = `${baseUrl}/sample_alert_reports/get_attribute_list/`;
    const payload = {
      data: {
        attribute,
      },
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSampleAlertAttributeList(attribute, companyId, addressCode) {
    const url = `${baseUrl}/sample_alerts/get_attribute_list/`;
    const payload = {
      data: {
        attribute,
      },
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getNotificationsDetailAttributeList(
    attribute,
    companyId,
    addressCode
  ) {
    const url = `${baseUrl}/alert_notifications/get_attribute_list/`;
    const payload = {
      data: {
        attribute,
      },
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  setNotificationAsSeen(id, companyId, addressCode) {
    const url = `${baseUrl}/alert_notifications/${id}/seen/`;
    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSampleTypesFromSatellite(params, companyId) {
    const url = `${baseUrl}/satellite/sample_type/?company_code=${companyId}`;
    const payload = {
      data: params,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId
    );
  },
  getEtlSample(companyId, addressCode, idSample) {
    const url = `${baseUrl}/etl/get_sample/`;
    const payload = {
      data: JSON.stringify({
        id_sample: idSample,
        store: true,
      }),
    };

    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getEtlSampleTypes(companyId, addressCode) {
    const url = `${baseUrl}/etl/get_sample_types/`;
    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getDestinationCountries(companyId, addressCode, sampleTypeId) {
    let url = `${baseUrl}/etl/get_lmr_countries/?atc_name=Alimentaria`;

    if (sampleTypeId != null) {
      url += `&sample_type_id=${sampleTypeId}`;
    }

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getDataEntityCountries(
    sampleId,
    countryCode,
    companyId,
    addressCode
  ) {
    const url = `${baseUrl}/etl/get_data_entity/`;
    const payload = {
      data: JSON.stringify({
        entity: 'lmrByTypeSample_Country',
        sample_type_id: sampleId,
        country_code: countryCode,
      }),
    };

    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getFoodAggregatedCalculations(params, companyId, addressCode) {
    let url = `${baseUrl}/samples/food_aggregated_calculations_v2/`;

    if (params.parameters.length > 0) {
      url += `?parameters=${params.parameters.join('&parameters=')}`;
    } else {
      params.parameters = '';
    }

    const payload = {
      data: params,
    };

    if (payload.data.parameters.length > 0) {
      delete payload.data.parameters;
    }

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSatelliteAnalyseType(params, companyId, addressCode) {
    const url = `${baseUrl}/satellite/analyse_type/?company_code=${companyId}`;
    const payload = {
      data: params,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSatelliteSampler(params, companyId, addressCode) {
    const url = `${baseUrl}/satellite/sampler/?company_code=${companyId}`;
    const payload = {
      data: params,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSatelliteThirdClient(params, companyId, addressCode) {
    const url = `${baseUrl}/satellite/third_client/?company_code=${companyId}`;
    const payload = {
      data: params,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSatelliteSampleTypes(params, companyId, addressCode) {
    const url = `${baseUrl}/satellite/sample_type/?company_code=${companyId}`;

    const payload = {
      data: params,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getFarms(withoutGeometries, companyId, addressCode, atc = null) {
    let url = `${baseUrl}/farms/?without_geometry=${withoutGeometries}`;

    if (atc) {
      url += `&atc_name=${atc}`;
    }

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getFarmsWithFilters(companyId, addressCode, params) {
    const url = `${baseUrl}/farms/`;

    const payload = {
      data: params,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getParcels(data, companyId, addressCode) {
    const url = `${baseUrl}/farms/parcels/`;

    const payload = {
      data,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  saveFarmParcelGeoFeature(
    geoFeature,
    childrenGeoFeatures,
    id,
    companyId,
    addressCode
  ) {
    const url = `${baseUrl}/farms/${id}/`;

    const payload = {
      data: JSON.stringify({
        farm_geofeature: geoFeature,
        children_geofeatures: childrenGeoFeatures,
      }),
    };

    return Request.async(
      url,
      payload,
      'PATCH',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getFarmGeoFeature(id, companyId, addressCode) {
    const url = `${baseUrl}/tree/location`;

    const payload = {
      data: { location: id },
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getFarmGeoFeatureByCode(id, companyId, addressCode) {
    const url = `${baseUrl}/tree/location`;

    const payload = {
      data: { code: id },
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  postHidrosophStationsForParcels(
    geometries,
    companyId,
    addressCode
  ) {
    const url = `${baseUrl}/hidrosoph/stations_for_parcels/`;

    const payload = {
      data: JSON.stringify(geometries),
    };

    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  postFarmStations(id, stations, companyId, addressCode) {
    const url = `${baseUrl}/farms/${id}/`;

    const payload = {
      data: JSON.stringify({
        stations,
      }),
    };

    return Request.async(
      url,
      payload,
      'PATCH',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getFarmsGeometries(companyId, addressCode) {
    const url = `${baseUrl}/farms/has_geometries/`;

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  updateFarmGeoFeature(geoFeature, id, companyId, addressCode) {
    const url = `${baseUrl}/farms/${id}/`;

    const payload = {
      data: JSON.stringify({
        farm_geofeature: geoFeature,
      }),
    };

    return Request.async(
      url,
      payload,
      'PATCH',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getFarmsAttributeList(attribute, companyId, addressCode) {
    const url = `${baseUrl}/farms/get_attribute_list/`;

    const payload = {
      data: { attribute },
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getAvaiableDatesForExpertSystem(
    companyId,
    addressCode,
    farmId,
    parcelId
  ) {
    const url = `${baseUrl}/nutritional_profile/get_evaluated/?farm_id=${farmId}&parcel_id=${parcelId}`;

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSamplesWithParametersData(params, companyId, addressCode) {
    const url = `${baseUrl}/samples/get_sample_with_parameters_data/`;
    const payload = { data: params };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getStudySatellite(companyId, addressCode, params) {
    const url = `${baseUrl}/satellite/study/?company_code=${companyId}&address_code=${addressCode}`;
    const payload = { data: params };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  confirmChangePassword(data, companyId, addressCode) {
    const url = `${baseUrl}/user/confirm_password`;

    const formData = new FormData();
    formData.append('password', data.password);
    formData.append('token', data.token);

    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: headerAuth,
        company: companyId,
        address: addressCode,
        'app-version': RELEASE_VERSION,
      },
      body: formData,
    })
      .then((res) => res)
      .catch((err) => err);
  },
  getSatelliteParameters(atc, sampleType, companyId, addressCode) {
    const url = `${baseUrl}/satellite/parameter/`;
    const payload = {
      data: {
        company_code: companyId,
        sample_type: sampleType,
        atc_name: atc,
      },
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSatelliteParametersUnits(atc, sampleType, companyId, addressCode) {
    const url = `${baseUrl}/satellite/parameter_unit/`;
    const payload = {
      data: {
        company_code: companyId,
        sample_type: sampleType.id,
        atc_name: atc,
      },
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSatelliteFoodChains(companyId, addressCode) {
    const url = `${baseUrl}/satellite/food_chain_list/`;
    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getClimateData(farmId, data, companyId, addressCode) {
    let url = `${baseUrl}/farms/${farmId}/climate_data/?init_date=${data.initDate}&end_date=${data.endDate}&grouped=${data.grouped}&formula=${data.formula}`;
    if (data.parameters.length > 0) {
      url += `&parameters=${data.parameters.join('&parameters=')}`;
    }
    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getWeatherWidgetData(farmId, data, companyId, addressCode) {
    const url = `${baseUrl}/farms/${farmId}/weather_widget_data/?init_date=${data.initDate}&end_date=${data.endDate}`;

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getNutritionalMonitoring(farmId, companyId, addressCode) {
    const url = `${baseUrl}/nutritional_profile/list_for_nutritional_monitoring/?farm_id=${farmId}`;

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getNutritionalMonitoringWithoutGeometry(
    companyId,
    addressCode,
    params = null
  ) {
    const url = `${baseUrl}/nutritional_profile/list_for_nutritional_monitoring/${
      params || ''
    }`;

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getLastSampleId(parcelId, companyId, addressCode) {
    const url = `${baseUrl}/samples/get_last_sample_id/?parcel_id=${parcelId}`;

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  saveGeoFeature(data, companyId, addressCode) {
    const url = `${baseUrl}/location`;

    const payload = {
      data: JSON.stringify({
        name: data.name,
        code: data.code,
        coordinates: data.coordinates,
        type: data.type,
        geo_feature: data.geoFeature,
        parent: data.parent,
      }),
    };

    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },

  updateGeoFeature(data, companyId, addressCode) {
    const url = `${baseUrl}/location/${data.id}`;

    const payload = {
      data: JSON.stringify({
        name: data.name,
        code: data.code,
        coordinates: data.coordinates,
        type: data.type,
        geo_feature: data.geoFeature,
        parent: data.parent,
      }),
    };

    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },

  getTreeLocation(companyId, addressCode) {
    const url = `${baseUrl}/tree/location?access_to_sernv=${true}`;

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSamplesWithGeometry(companyId, addressCode, params) {
    const queryParams = new URLSearchParams();
    queryParams.set('farm_id', params.farm_id);

    if (params.samplingDate_after != null) {
      queryParams.set(
        'samplingDate_after',
        params.samplingDate_after
      );
    }

    if (params.samplingDate_before != null) {
      queryParams.set(
        'samplingDate_before',
        params.samplingDate_before
      );
    }

    if (params.sample_type_family_id != null) {
      queryParams.set(
        'sample_type_family_id',
        params.sample_type_family_id
      );
    }

    if (params.sample_type_id != null) {
      queryParams.set('sample_type_id', params.sample_type_id);
    }

    if (params.parcel_id != null) {
      queryParams.set('parcel_id', params.parcel_id);
    }

    if (params.study != null && params.study !== '') {
      queryParams.set('study', params.study);
    }

    if (params.parameter != null && params.parameter !== '') {
      queryParams.set('parameter_id', params.parameter);
    }

    if (params.atc_name != null && params.atc_name !== '') {
      queryParams.set('atc_name', params.atc_name);
    }

    const url = `${baseUrl}/samples/samples_with_geometry/?${queryParams.toString()}`;

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  fetchTimelineIndexes(
    location,
    initDate,
    endDate,
    index,
    companyId,
    addressCode
  ) {
    const url = `${geoServerIndexStats}`;

    const payload = {
      data: {
        client: geoServerWorkspace,
        location,
        init_date: initDate,
        end_date: endDate,
        index: [index],
      },
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getFeatureInt(props) {
    const queryParams = new URLSearchParams();

    queryParams.set('legend_desc', props.legend_desc);
    queryParams.set('geometry_points', props.geometry_points);
    queryParams.set('geometry_region', props.geometry_region);
    queryParams.set('ref_values', props.ref_values);
    queryParams.set('color_map', props.color_map);
    queryParams.set('name', props.name);

    const url = `${geoServerGetFeatureInt}?${queryParams.toString()}`;

    // const payload = {
    //   data: {
    //     legend_desc: props.legend_desc,
    //     geometry_points: props.geometry_points,
    //     geometry_region: props.geometry_region,
    //     ref_values: props.ref_values,
    //     color_map: props.color_map,
    //     name: props.name,
    //   },
    // };

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth
      // companyId,
      // addressCode
    );
  },
  getComparativeGisTable(companyId, addressCode, params = null) {
    const url = `${baseUrl}/farms/comparative_gis_table/`;

    const payload = {
      data: params,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getNutritionalGraphicData(data, companyId, addressCode) {
    const url = `${baseUrl}/samples/nutritional_graphic_data/?date_after=${data.dateAfter}&date_before=${data.dateBefore}&parameters=${data.parameters}&parcel_id=${data.parcelId}`;

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSamplesWithParameterByFarmAndParcel(
    companyId,
    addressCode,
    { parcelId, farmId }
  ) {
    const url = `${baseUrl}/samples/samples_with_parameter_by_farm_and_parcel/?parcel_id=${parcelId}&farm_id=${farmId}`;

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getMirData(dates, companyId, addressCode, farmId) {
    const url = `${baseUrl}/hidrosoph/get_mir_data/?init_date=${dates.initDate}&end_date=${dates.endDate}&farm=${farmId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getMirDataStations(dates, companyId, addressCode, stationId) {
    const url = `${baseUrl}/hidrosoph/get_mir_data/?init_date=${dates.initDate}&end_date=${dates.endDate}&source=${stationId}`;

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getLastMirData(companyId, addressCode, farmId) {
    const url = `${baseUrl}/hidrosoph/get_last_mir_data/?farm=${farmId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getGisAttributeList(attribute, companyId, addressCode) {
    const url = `${baseUrl}/samples/get_gis_attribute_list/?attribute=${attribute}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getSamplesReports(params, companyId, addressCode) {
    const url = `${baseUrl}/samples/download_files/`;
    const payload = {
      data: params,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  checkAccessToAdvancedServices(companyId, addressCode) {
    const url = `${baseUrl}/farms/check_access_to_advanced_services/?company_code=${companyId}&address_code=${addressCode}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getParcelServices(farmId, companyId, addressCode) {
    const url = `${baseUrl}/farms/get_farm_services/`;

    const payload = {
      data: {
        farm_id: farmId,
      },
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getStationsList(location, companyId, addressCode) {
    const url = `${baseUrl}/hidrosoph/?location=${location}`;

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getStationsListFarm(farm, companyId, addressCode) {
    const url = `${baseUrl}/hidrosoph/?farm=${farm}`;

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  searchStationByGeometry(geometry, companyId, addressCode) {
    const url = `${baseUrl}/hidrosoph/search_station_by_geometry/`;

    const payload = {
      data: JSON.stringify(geometry),
    };

    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  saveStations(location, stations, companyId, addressCode) {
    const url = `${baseUrl}/hidrosoph/`;

    const payload = {
      data: JSON.stringify({
        location,
        stations,
      }),
    };

    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getStations(location, companyId, addressCode) {
    const url = `${baseUrl}/hidrosoph/?location=${location}`;

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  updateStations(id, location, stations, companyId, addressCode) {
    const url = `${baseUrl}/hidrosoph/${id}/`;

    const payload = {
      data: JSON.stringify({
        location,
        stations,
      }),
    };

    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getNutritionalMonitoringWGRAttributeList(
    attribute,
    companyId,
    addressCode
  ) {
    const url = `${baseUrl}/farms/get_parcel_attribute_list/`;

    const payload = {
      data: { attribute },
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getRefValues(data, companyId, addressCode) {
    const url = `${baseUrl}/samples/get_parameter_reference_values/`;

    const payload = {
      data,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getNutritionalMonitoringWithoutGeometryWithFilters(
    companyId,
    addressCode,
    params
  ) {
    const url = `${baseUrl}/nutritional_profile/list_for_nutritional_monitoring/`;
    const payload = {
      data: params,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getNutritionalProfileEvaluation(
    companyId,
    addressCode,
    id,
    params
  ) {
    const url = `${baseUrl}/nutritional_profile_assessment/${id}/`;

    const payload = {
      data: params,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getEntityV2(data, companyId, addressCode) {
    let url = `${baseUrl}/etl/get_sample_type_lmr_by_countries/?sample_type=${data.sampleType}&lmr_origin=${data.lmrOrigin}`;

    if (data.lmrsDestiny.length > 0) {
      url += `&lmr_destiny=${data.lmrsDestiny.join('&lmr_destiny=')}`;
    }

    const payload = {
      data: JSON.stringify({
        sample_type: data.sampleType,
        lmr_origin: data.lmrOrigin,
      }),
    };

    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getReceptionAlert(params, companyId, addressCode) {
    const url = `${baseUrl}/reception_alert/${params.contactCode}/`;

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  createReceptionAlert(params, companyId, addressCode) {
    const url = `${baseUrl}/reception_alert/`;

    const payload = {
      data: JSON.stringify({
        contact_code: params.contactCode,
      }),
    };

    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  deleteReceptionAlert(params, companyId, addressCode) {
    const url = `${baseUrl}/reception_alert/${params.contactCode}/`;

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
  getQueryParams(params) {
    const queryParams = new URLSearchParams();
    for (const param in params) {
      if (Object.hasOwnProperty.call(params, param)) {
        const value = params[param];
        if (typeof value !== 'undefined') {
          queryParams.set(param, value);
        }
      }
    }
    return queryParams.toString();
  },
  getElementsByQueryParams(
    companyId,
    addressCode,
    module,
    queryParams
  ) {
    let url = `${baseUrl}/${module}`;
    url += `?${this.getQueryParams({ ...queryParams })}`;

    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
      addressCode
    );
  },
};
