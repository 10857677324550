var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-app',{attrs:{"params":_vm.f7params}},[_c('ConsentGDPR'),_vm._v(" "),(
    _vm.isUserLoggedIn && !_vm.showTokenPage && !_vm.showPrivacyPolicyPage
  )?_c('LeftPanel',{attrs:{"panel-options":_vm.options}}):_vm._e(),_vm._v(" "),(
    _vm.isUserLoggedIn && !_vm.showTokenPage && !_vm.showPrivacyPolicyPage
  )?_c('FilterPanel'):_vm._e(),_vm._v(" "),(
    _vm.isUserLoggedIn && !_vm.showTokenPage && !_vm.showPrivacyPolicyPage
  )?_c('Navbar',{attrs:{"socket":_vm.webSocket}}):_vm._e(),_vm._v(" "),(_vm.showLogin && !_vm.showTokenPage && !_vm.showPrivacyPolicyPage)?_c('login-page'):_vm._e(),_vm._v(" "),(
    _vm.showPasswordRecovery &&
    !_vm.showTokenPage &&
    !_vm.showPrivacyPolicyPage
  )?_c('password-recovery-page'):_vm._e(),_vm._v(" "),(_vm.isUserLoggedIn && !_vm.showPrivacyPolicyPage)?_c('f7-view',{staticClass:"safe-areas",attrs:{"main":"","url":"/","reload-pages":true}}):_vm._e(),_vm._v(" "),(_vm.showTokenPage && !_vm.showPrivacyPolicyPage)?_c('f7-view',{staticClass:"safe-areas",attrs:{"main":"","url":"/token-recovery","reload-pages":true}}):_vm._e(),_vm._v(" "),(_vm.showPrivacyPolicyPage)?_c('f7-view',{staticClass:"safe-areas",attrs:{"main":"","url":"/privacy-policy","reload-pages":true}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }