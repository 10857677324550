<template>
  <div>
    <div
      class="filterButtonPosition"
      style="display: flex; align-items: center; gap: 0.4rem"
    >
      <f7-button
        fill
        class="whiteFontColor button-filter"
        panel-toggle="right"
      >
        <span class="ic-filter"></span> {{ $t('filter') }}
      </f7-button>
      <ContextMenu :id="'context-menu-btn'" :items="getItems" />
    </div>
    <DxDataGrid
      :data-source="store"
      :show-borders="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-colon="false"
      :hover-state-enabled="true"
      :remote-operations="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :no-data-text="$t('noDataTextSummary')"
      @exporting="onExporting"
      @cell-prepared="onCellPrepared"
      @context-menu-preparing="addMenuItems"
      @content-ready="init"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxScrolling mode="standard" />
      <DxStateStoring
        :enabled="true"
        type="custom"
        storage-key="gridallHomeSamples"
        :custom-load="loadState"
        :custom-save="customSave"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[5, 10, 20]"
        :show-info="true"
        info-text="{2} items"
        :visible="true"
      />
      <DxExport :enabled="false" />
      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('searchSampleCode')"
      />

      <DxColumn
        data-field="summary_semaphore"
        header-cell-template="cellTemplateHeaderDots"
        :width="55"
        :allow-sorting="true"
        cell-template="cellTemplateDots"
        :fixed="true"
        fixed-position="left"
      />
      <DxColumn
        data-field="code"
        :caption="$t('agronomySample.summary.sampleCode')"
        alignment="left"
        :allow-header-filtering="false"
        :fixed="true"
      />
      <DxColumn
        data-field="atc_name"
        :caption="$t('atc')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="sample_type"
        :caption="$t('agronomySample.summary.sampleType')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="sample_name"
        :caption="$t('agronomySample.summary.description')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="reception_date"
        :caption="$t('foodSamples.receptionDate')"
        alignment="left"
        data-type="date"
        format="dd/MM/yyyy"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="end_date"
        :caption="$t('foodSamples.endDate')"
        alignment="left"
        data-type="date"
        format="dd/MM/yyyy"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field="analyse_type"
        :caption="$t('foodSamples.analysisType')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="status"
        :caption="$t('status')"
        alignment="left"
        :fixed="true"
        fixed-position="right"
        :width="150"
        :allow-header-filtering="false"
        cell-template="cellTemplateStatus"
      />
      <DxColumn data-field="id" :visible="false" />

      <DxColumn
        :buttons="updatedEditButtons"
        :fixed="true"
        fixed-position="right"
        type="buttons"
        width="80"
      />
      <template #cellTemplateHeaderDots="{ data }">
        <div>
          <PopUp position="top">
            <div style="padding: 10px">
              <div class="pop-up-info">
                <img
                  :src="getDotColorImage('grey')"
                  width="15"
                  height="15"
                />
                <div style="padding-left: 5px">
                  {{
                    $t('agronomySample.summary.greyDotDescription')
                  }}
                </div>
              </div>
              <div class="pop-up-info">
                <img
                  :src="getDotColorImage('green')"
                  width="15"
                  height="15"
                />
                <div style="padding-left: 5px">
                  {{
                    $t('agronomySample.summary.greenDotDescription')
                  }}
                </div>
              </div>
              <div class="pop-up-info">
                <img
                  :src="getDotColorImage('orange')"
                  width="15"
                  height="15"
                />
                <div style="padding-left: 5px">
                  {{
                    $t('agronomySample.summary.orangeDotDescription')
                  }}
                </div>
              </div>
              <div class="pop-up-info">
                <img
                  :src="getDotColorImage('red')"
                  width="15"
                  height="15"
                />
                <div style="padding-left: 5px">
                  {{ $t('agronomySample.summary.redDotDescription') }}
                </div>
              </div>
            </div>
          </PopUp>
        </div>
      </template>
      <template #cellTemplateDots="{ data }">
        <div class="dots-parent-container">
          <img
            v-if="data.value !== null"
            width="20"
            height="20"
            :src="getDotColorImage(data.value)"
          />
        </div>
      </template>
      <template #cellTemplateStatus="{ data }">
        <div
          :class="$helpers.getStatusClass(data.data.status_code)"
          style="text-align: center"
        >
          {{ data.data.status }}
        </div>
      </template>
    </DxDataGrid>

    <!-- <ContextMenu :id="'context-menu-btn'" :items="getItems" /> -->

    <NotificationSpinner
      v-if="notificationMessage.length > 0"
      :message="notificationMessage"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxStateStoring,
  DxPager,
  DxPaging,
  DxScrolling,
  DxExport,
} from 'devextreme-vue/data-grid';

import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { trigger } from 'devextreme/events';
import PopUp from '@/components/UtilityComponents/popUpInfo/popUp.vue';
import ContextMenu from '@/components/contextMenu/index.vue';
import NotificationSpinner from '@/components/NotificationSpinner';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxStateStoring,
    DxPager,
    DxPaging,
    DxScrolling,
    DxExport,
    PopUp,
    ContextMenu,
    NotificationSpinner,
  },
  props: {
    store: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editButtons: [
        {
          icon: 'find',
          onClick: this.viewAtc,
        },
        {
          icon: 'download',
          onClick: this.download,
        },
      ],
      types: {},
      itemsDisabled: [
        {
          icon: 'excel',
          text: this.$t('excelDownload'),
          onItemClick: () => {
            this.onExporting(this.grid);
          },
        },
        {
          icon: 'pdf',
          text: this.$t('pdfReportDownload'),
          onItemClick: () => {
            // this.onExporting(this.grid);
          },
          disabled: true,
        },
      ],
      itemsEnabled: [
        {
          icon: 'excel',
          text: this.$t('excelDownload'),
          onItemClick: () => {
            this.onExporting(this.grid);
          },
        },
        {
          icon: 'pdf',
          text: this.$t('pdfReportDownload'),
          onItemClick: async () => {
            this.setDisableContext(true);

            try {
              this.notificationMessage = this.$t('downloading');
              const b64 = await this.getSamplesZip();

              const link = document.createElement('a');
              link.href = `data:application/octet-stream;base64,${b64}`;
              link.download = `besafer-reports-${new Date().toLocaleString()}.zip`;
              link.click();

              URL.revokeObjectURL(link.href);
              this.notificationMessage = this.$t('downloadedFile');
            } catch (e) {
              this.notificationMessage = '';
              this.$notifyDX(
                {
                  message: this.$t(
                    this.$helpers.getFilteredErrorMessage(
                      JSON.parse(e.message).error
                    )
                  ),
                  width: 550,
                },
                'error',
                NOTIFY_TIME_LONG
              );
            } finally {
              this.setDisableContext(false);

              setTimeout(() => {
                this.notificationMessage = '';
              }, 2000);
            }
          },
        },
      ],
      grid: null,
      notificationMessage: '',
    };
  },
  computed: {
    getItems() {
      return this.disableContext
        ? this.itemsDisabled
        : this.itemsEnabled;
    },
    ...mapState('samples', ['disableContext', 'isDownloadingReport']),
    ...mapState('agronomySamples', {
      allAgronomySamples: 'allSamples',
    }),
    ...mapState('foodSamples', {
      allFoodSamples: 'allSamples',
    }),
    ...mapState('environmentSamples', {
      allEnvironmentSamples: 'allSamples',
    }),
    ...mapState('miningSamples', {
      allMiningSamples: 'allSamples',
    }),
    ...mapState('healthSecuritySamples', {
      allHealthSecuritySamples: 'allSamples',
    }),
    updatedEditButtons() {
      return this.isDownloadingReport
        ? [
            {
              icon: 'find',
              onClick: this.viewAtc,
            },
            {
              icon: 'download',
              onClick: this.download,
              disabled: true,
            },
          ]
        : [
            {
              icon: 'find',
              onClick: this.viewAtc,
            },
            {
              icon: 'download',
              onClick: this.download,
              disabled: this.isDisabled,
            },
          ];
    },
  },
  beforeMount() {
    this.setIsDownloadingReport(false);
    this.setDisableContext(false);
    this.setAllFilterParams({ atc_name: '' });
  },
  methods: {
    loadState() {
      let state = localStorage.getItem('gridallHomeSamples');
      if (state) {
        state = JSON.parse(state);
        state.searchText = '';
      }
      return state;
    },
    customSave(state) {
      localStorage.setItem(
        'gridallHomeSamples',
        JSON.stringify(state)
      );
    },
    getDotColorImage(color) {
      return require(`../../../../assets/dot-colors/${color}.svg`)
        .default;
    },
    init(e) {
      this.setSamples(e.component?.getDataSource()?.items());
      this.grid = e;
    },
    formatExcel(samples) {
      const config = [
        {
          sheet: 'Samples',
          columns: [
            {
              label: this.$t('agronomySample.summary.sampleCode'),
              value: 'code',
            },
            {
              label: this.$t('agronomySample.summary.description'),
              value: 'report_observations',
            },
            {
              label: this.$t('foodSamples.receptionDate'),
              value: 'reception_date',
            },
          ],
          content: [
            ...samples.map((sample) => {
              sample.parameters.forEach((parameter) => {
                sample.sample[
                  `${parameter.parameter} ${parameter.unit}`
                ] = parameter.value;
              });

              return sample.sample;
            }),
          ],
        },
      ];

      samples.forEach((sample) => {
        sample.parameters.forEach((parameter) => {
          config[0].columns.push({
            label: `${parameter.parameter} ${parameter.unit}`,
            value: `${parameter.parameter} ${parameter.unit}`,
          });
        });
      });

      const fileSettings = {
        fileName: `besafer-samples-parameters-${this.$helpers.getDateFormatedDayMonthYear(
          new Date()
        )}`,
        extraLength: 3,
        writeOptions: {},
      };

      this.$jsonToXLSX(config, fileSettings, () => {
        this.setDisableContext(false);

        this.$notifyDX(
          {
            message: this.$t('xslxDownloadedSuccessfully'),
            width: 400,
          },
          'success',
          2000
        );
      });
    },
    onToolbarPreparing(e) {
      // e.toolbarOptions.items.unshift({
      //   location: 'after',
      //   widget: 'dxButton',
      //   options: {
      //     icon: 'chevrondown',
      //     elementAttr: {
      //       id: 'context-menu-btn',
      //     },
      //     onClick: ({ event }) => {
      //       event.preventDefault();
      //     },
      //   },
      // });
    },
    async download(e) {
      this.types = {
        food: this.$t('Food'),
        agronomy: this.$t('Agronomy'),
        environment: this.$t('Environment'),
        mining: this.$t('Mining'),
        healthAndSecurity: this.$t('Health and Security'),
      };

      this.setReportSampleId(e.row.data.sample_id);

      if (e.row.data.atc_name === this.types.food) {
        const dxContextMenuEvent = {
          ...e.event,
          type: 'dxcontextmenu',
        };
        e.event.stopPropagation();

        trigger(
          e.row.cells[e.row.cells.length - 1].cellElement,
          dxContextMenuEvent
        );
      } else {
        this.setIsDownloadingReport(true);
        this.notificationMessage = this.$t('downloading');

        try {
          const report = await this.getReport();

          if (report.name && report.pdfB64) {
            const link = document.createElement('a');
            link.href = `data:application/octet-stream;base64,${report.pdfB64}`;
            link.download = report.name;
            link.click();

            URL.revokeObjectURL(link.href);

            this.notificationMessage = this.$t('downloadedFile');
          } else {
            this.notificationMessage = '';
            this.$notifyDX(
              {
                message: this.$t('sampleHasNoReport'),
                width: 450,
              },
              'info',
              2000
            );
          }
        } catch (error) {
          this.notificationMessage = '';
          this.setIsDownloadingReport(false);
          this.$notifyDX(
            {
              message: this.$t('reportNotAvailable'),
              width: 550,
            },
            'error',
            NOTIFY_TIME_LONG
          );
        } finally {
          setTimeout(() => {
            this.notificationMessage = '';
          }, 2000);
          this.setIsDownloadingReport(false);
        }
      }
    },
    isDisabled(e) {
      return e?.row?.data?.status_code !== '3';
    },
    addMenuItems(e) {
      this.types = {
        food: this.$t('Food'),
        agronomy: this.$t('Agronomy'),
        environment: this.$t('Environment'),
        mining: this.$t('Mining'),
        healthAndSecurity: this.$t('Health and Security'),
      };

      if (!e.row.data) return;

      if (
        e.column.name !== 'buttons' ||
        e.row.data.atc_name !== this.types.food
      ) {
        return;
      }

      if (e.target === 'content') {
        if (!e.items) e.items = [];

        e.items.push({
          icon: 'pdffile',
          text: this.$t('generalReport'),
          onItemClick: async () => {
            this.setIsDownloadingReport(true);

            this.notificationMessage = this.$t('downloading');

            try {
              const report = await this.getReport();

              if (report.name && report.pdfB64) {
                const link = document.createElement('a');
                link.href = `data:application/octet-stream;base64,${report.pdfB64}`;
                link.download = report.name;
                link.click();

                URL.revokeObjectURL(link.href);

                this.notificationMessage = this.$t('downloadedFile');
              } else {
                this.notificationMessage = '';
                this.$notifyDX(
                  {
                    message: this.$t('sampleHasNoReport'),
                    width: 450,
                  },
                  'info',
                  2000
                );
              }
            } catch (error) {
              this.$notifyDX(
                {
                  message: this.$t('reportNotAvailable'),
                  width: 550,
                },
                'error',
                NOTIFY_TIME_LONG
              );
            } finally {
              setTimeout(() => {
                this.notificationMessage = '';
              }, 2000);
              this.setIsDownloadingReport(false);
            }
          },
        });

        e.items.push({
          icon: 'pdffile',
          text: this.$t('tracesReport'),
          onItemClick: async () => {
            this.setIsDownloadingReport(true);

            this.notificationMessage = this.$t('downloading');

            const DOC_TYPE_TRACES = 2;
            try {
              const report = await this.getReport(DOC_TYPE_TRACES);

              if (report.name && report.pdfB64) {
                const link = document.createElement('a');
                link.href = `data:application/octet-stream;base64,${report.pdfB64}`;
                link.download = report.name;
                link.click();

                URL.revokeObjectURL(link.href);

                this.notificationMessage = this.$t('downloadedFile');
              } else {
                this.notificationMessage = '';
                this.$notifyDX(
                  {
                    message: this.$t('sampleHasNoReport'),
                    width: 450,
                  },
                  'info',
                  2000
                );
              }
            } catch (error) {
              this.$notifyDX(
                {
                  message: this.$t('reportNotAvailable'),
                  width: 550,
                },
                'error',
                NOTIFY_TIME_LONG
              );
            } finally {
              setTimeout(() => {
                this.notificationMessage = '';
              }, 2000);
              this.setIsDownloadingReport(false);
            }
          },
        });
      }
    },
    async viewAtc(e) {
      this.setSampleFetchedFromEtl(false);
      this.setLastRouteHome(true);
      this.setLastRouteGeotoolboxSamples(false);

      this.setCurrentSampleId(e.row.data.id);
      this.setReportSampleId(e.row.data.sample_id);
      this.setReportSampleCode(e.row.data.code);

      await this.getCurrentSample();

      await this.goToSampleDetailsByAtc(e.row.data.atc_name, e);
    },
    async goToSampleDetailsByAtc(atcName, e) {
      this.types = {
        food: this.$t('Food'),
        agronomy: this.$t('Agronomy'),
        environment: this.$t('Environment'),
        mining: this.$t('Mining'),
        healthAndSecurity: this.$t('Health and Security'),
      };

      if (atcName === this.types.food) {
        this.setCurrentFilter('foodDetails');
        this.setIsNoRp(e.row.data.value_report === 'CMA');
        this.setSelectedLimits([this.$t('positives')]);

        if (e.row.data.value_report !== 'CMA') {
          if (e.row.data.retailers) {
            const chains = [...e.row.data.retailers];

            const res = [];

            chains.forEach((chainData) => {
              chainData.Reglas.forEach((chain) => {
                if (
                  res.length > 0 &&
                  res.some(
                    (el) => el.Supermercado === chainData.Supermercado
                  )
                ) {
                  const indexRes = res.findIndex(
                    (el) => el.Supermercado === chainData.Supermercado
                  );
                  res[indexRes] = {
                    ...res[indexRes],
                    [`Regla ${chain.Regla}`]: chain.Regla,
                    [`Resultado ${chain.Regla}`]: chain.Resultado,
                    [`CodRegla ${chain.Regla}`]: chain.CodRegla,
                    Supermercado: chainData.Supermercado,
                    Confirmado: chainData.Confirmado,
                  };
                } else {
                  res.push({
                    [`Resultado ${chain.Regla}`]: chain.Resultado,
                    [`Regla ${chain.Regla}`]: chain.Regla,
                    [`CodRegla ${chain.Regla}`]: chain.CodRegla,
                    Supermercado: chainData.Supermercado,
                    Confirmado: chainData.Confirmado,
                    ...chain,
                  });
                }
              });
            });

            this.setEuropeanChains(res);
          } else {
            this.setEuropeanChains([]);
          }
        }

        this.pushRoute({
          name: this.$t('Food'),
          disabled: false,
          page: 'FoodSamplesPage',
          active: false,
        });

        this.pushRoute({
          name: this.$t('sampleSummary'),
          disabled: true,
          page: 'FoodSampleDetailPage',
          active: true,
        });

        this.$f7router.navigate(
          {
            name: 'FoodSampleDetailPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } else if (atcName === this.types.agronomy) {
        this.setCurrentFilter('agronomy');

        this.pushRoute({
          name: this.$t('Agronomy'),
          disabled: false,
          page: 'AgronomySamplesPage',
          active: false,
        });

        this.pushRoute({
          name: this.$t('sampleSummary'),
          disabled: true,
          page: 'AgronomySamplesDetailsPage',
          active: true,
        });

        this.$f7router.navigate(
          {
            name: 'AgronomySamplesDetailsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } else if (atcName === this.types.environment) {
        this.setCurrentFilter('environmentDetails');

        this.pushRoute({
          name: this.$t('Environment'),
          disabled: false,
          page: 'EnvironmentSamplesPage',
          active: false,
        });

        this.pushRoute({
          name: this.$t('sampleSummary'),
          disabled: true,
          page: 'EnvironmentSamplesDetailsPage',
          active: true,
        });

        this.$f7router.navigate(
          {
            name: 'EnvironmentSamplesDetailsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } else if (atcName === this.types.mining) {
        this.setCurrentFilter('miningDetails');

        this.pushRoute({
          name: this.$t('Mining'),
          disabled: false,
          page: 'MiningSamplesPage',
          active: false,
        });

        this.pushRoute({
          name: this.$t('sampleSummary'),
          disabled: true,
          page: 'MiningSamplesDetailsPage',
          active: true,
        });

        this.$f7router.navigate(
          {
            name: 'MiningSamplesDetailsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } else if (atcName === this.types.healthAndSecurity) {
        this.setCurrentFilter('healthSecurityDetails');

        this.pushRoute({
          name: this.$t('Health and Security'),
          disabled: false,
          page: 'HealthSecuritySamplesPage',
          active: false,
        });

        this.pushRoute({
          name: this.$t('sampleSummary'),
          disabled: true,
          page: 'HealthSecuritySamplesDetailsPage',
          active: true,
        });

        this.$f7router.navigate(
          {
            name: 'HealthSecuritySamplesDetailsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      }
    },
    onCellPrepared(e) {
      if (e.rowType === 'data') {
        if (!e.data.seen) {
          e.cellElement.classList.add('hasntBeenSeen');
        }
      }
    },
    onExporting(e) {
      e.component.beginUpdate();
      e.component.columnOption('ID', 'visible', true);
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(
        `${`${this.$t(
          'agronomySample.exportExcelSampleTable'
        )}-${this.$t('Home')}`}`
      );

      exportDataGrid({
        component: e.component,
        worksheet,
      })
        .then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], {
                type: 'application/octet-stream',
              }),
              `besafer-samples-${this.$helpers.getDateFormatedDayMonthYear(
                new Date()
              )}.xlsx`
            );
          });
        })
        .then(() => {
          e.component.columnOption('ID', 'visible', false);
          e.component.endUpdate();
        });

      e.cancel = true;
    },
    ...mapActions('filter', [
      'setCurrentFilter',
      'setCurrentSampleId',
    ]),
    ...mapActions('samples', [
      'getReport',
      'setReportSampleId',
      'getCurrentSample',
      'setReportSampleCode',
      'setDisableContext',
      'getSamplesWithParameters',
      'setAllFilterParams',
      'getSamplesZip',
      'setIsDownloadingReport',
      'setSampleFetchedFromEtl',
      'setLastRouteHome',
      'setLastRouteGeotoolboxSamples',
    ]),
    ...mapActions('homeSamples', ['setSamples']),
    ...mapActions('foodSamples', ['setIsNoRp', 'setEuropeanChains']),
    ...mapActions('foodDetailsFilter', ['setSelectedLimits']),
    ...mapActions('breadcrumb', ['pushRoute']),
  },
};
</script>
